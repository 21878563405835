/* eslint-disable */

import { ArrowDownIcon } from '@chakra-ui/icons';
import { MdBuild , MdCall } from "react-icons/md"
import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
} from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import { MdArrowUpward, MdCancel, MdCheckCircle, MdError } from 'react-icons/md';

const columnHelper = createColumnHelper();
const lightModeColors = [
  '#FF5733', 
  '#33FF57',  
  '#3357FF',  
  '#FF33A1',  
  '#FFC300',  
];

const darkModeColors = [
  '#C70039', 
  '#28A745', 
  '#007BFF', 
  '#FF69B4', 
  '#FFC300',
];

const userColors = {};
import * as XLSX from 'xlsx';



const getUserColor = (userId, isDarkMode) => {
  if (userColors[userId]) {
    return userColors[userId];
  }
  const colors = isDarkMode ? darkModeColors : lightModeColors;
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  userColors[userId] = randomColor;
  return randomColor;
};


export default function UsersTable(props) {
  const { tableData } = props;
  const [filterInput, setFilterInput] = useState({});
  const [presentData, setPresentData] = useState(tableData);
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(presentData);      
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const fileName = 'users_data.xlsx';
    XLSX.writeFile(workbook, fileName);
  };

  const handleFilterChange = (columnId, value) => {
    setFilterInput((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  useEffect(() => {
    const filteredData = tableData.filter((row) =>
      columns.every((column) => {
        const columnId = column.id;
        const filterValue = filterInput[columnId];
        if (!filterValue) return true;
        return row[columnId]?.toString().toLowerCase().includes(filterValue.toLowerCase());
      })
    );
    setData(filteredData);
    setPresentData(filteredData);
  }, [filterInput, tableData]);

  const columns = [
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Username
        </Text>
      ),
      cell: (info) => (
        <Text color={getUserColor(info.getValue())} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('contact_number', {
      id: 'contact_number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Contact Number
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('daily_pnl', {
      id: 'daily_pnl',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Daily PnL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('weekly_pnl', {
      id: 'weekly_pnl',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Weekly PnL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('monthly_pnl', {
      id: 'monthly_pnl',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Monthly PnL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('live', {
      id: 'live',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Live
        </Text>
      ),
      cell: (info) => (
        <Text color={info.getValue() ? 'green.500' : 'red.500'} fontSize="sm" fontWeight="700">
          {info.getValue() ? 'Yes' : 'No'}
        </Text>
      ),
    }),
    columnHelper.accessor('group', {
      id: 'group',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Group
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('balance', {
      id: 'balance',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Balance
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),  
    columnHelper.accessor('blacklist', {
      id: 'blacklist',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Blacklist
        </Text>
      ),
      cell: (info) => (
        <Text color={info.getValue() === 0 ? 'green.500' : 'red.500'} fontSize="sm" fontWeight="700">
          {info.getValue() === 0 ? 'Live' : info.getValue() === 1 ? 'Day' : 'Month'}
        </Text>
      ),
    }),  
];
  

  const inputTextColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Orders
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Box>
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                        <Input
                            my="2"
                            placeholder=""
                            value={filterInput[header.column.columnDef.id] || ''}
                            onChange={(e) => handleFilterChange(header.column.columnDef.id, e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            color={inputTextColor}
                        />
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      <Button leftIcon={<MdBuild />} colorScheme='green' variant='solid' w={['80%', '60%','50%', '20%']}
        alignSelf="center"
        onClick={handleDownload}
        >
        Download excel
      </Button>
    </Card>
  );
}
