export const columnDataUsers = [
  {
    Header: "Username",
    accessor: "username", // Maps to the username field
  },
  {
    Header: "Contact Number",
    accessor: "contact_number", // Maps to the contact_number field
  },
  {
    Header: "Daily PnL",
    accessor: "daily_pnl", // Maps to the daily_pnl field
  },
  {
    Header: "Weekly PnL",
    accessor: "weekly_pnl", // Maps to the weekly_pnl field
  },
  {
    Header: "Monthly PnL",
    accessor: "monthly_pnl", // Maps to the monthly_pnl field
  },
  {
    Header: "Live Status",
    accessor: "live", // Maps to the live field
    Cell: ({ value }) => (value ? "Yes" : "No"), // Optional: Display "Yes" or "No" for boolean values
  },
  {
    Header: "Group",
    accessor: "group", // Maps to the group field
  },
  {
    Header: "Balance",
    accessor: "balance", // Maps to the balance field
  },
  {
    Header: "Blacklist",
    accessor: "blacklist", // Maps to the balance field
  },
];
