// Chakra imports
import {
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'

import Card from "components/card/Card.js";
import React from "react";
import { Input } from '@chakra-ui/react';

function NumberInputPer(props) {
  const { ...rest } = props;

  const textColorSecondary = useColorModeValue("gray.700", "whiteAlpha.800");

  return (
    <NumberInput
      // onChange={(valueString) => setValue(valueString)}
      defaultValue={1.00}
      step={0.01}
      max={100}
      {...rest}
    >
      <NumberInputField  color={textColorSecondary}/>
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}


export default function CreateOrder(props) {
  const { orderData, setOrderData, handleCreateOrder, isLoading , handleLoginUsers,...rest } = props;

  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const textColorSecondary = useColorModeValue("gray.700", "whiteAlpha.800");

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}
      >
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="30%">
            Symbol
          </Text>   
          <Input placeholder='BTC/USDT' size='md' m="4" color={textColorSecondary}
            onChange={(e) => setOrderData({ ...orderData, symbol: e.target.value })}
          />
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4"  width="30%">
            Multiplier
          </Text>
          <NumberInput defaultValue={1} max={300} width="100%" m="4"
            onChange={(valueString) => setOrderData({ ...orderData, multiplier: valueString })} 
          >
            <NumberInputField color={textColorSecondary}/>
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="20%">
            Order Type
          </Text>   
          <RadioGroup defaultValue='market' m="4"
            onChange={(value) => setOrderData({ ...orderData, order_type: value })} 
          >
            <Stack spacing={5} direction='row'  align="center" width="20%">
              <Radio colorScheme='red' value='market'>
                Market
              </Radio>
              <Radio colorScheme='green' value='limit'>
                Limit
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="20%">
            Side
          </Text>   
          <RadioGroup defaultValue='buy' m="4"
            onChange={(value) => setOrderData({ ...orderData, side: value })} 
          >
            <Stack spacing={12} direction='row'  align="center" width="20%">
              <Radio colorScheme='green' value='buy'>
                Buy
              </Radio>
              <Radio colorScheme='red' value='sell'>
                Sell
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="20%">
            market
          </Text>   
          <RadioGroup defaultValue='future' m="4"
            onChange={(value) => setOrderData({ ...orderData, market: value })} 
          >
            <Stack spacing={9} direction='row'  align="center" width="20%">
              <Radio colorScheme='green' value='spot'>
                Spot
              </Radio>
              <Radio colorScheme='red' value='future'>
                Future
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="30%">
            Leverage
          </Text>   
          <NumberInput defaultValue={1} max={300} width="100%" m="4"
            onChange={(valueString) => setOrderData({ ...orderData, leverage: valueString })} 
          >
            <NumberInputField color={textColorSecondary}/>
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="30%">
            Takeprofit
          </Text>   
          <NumberInputPer  width="100%" m="4"
            onChange={(valueString) => setOrderData({ ...orderData, takeprofit: valueString })} 
          />
        </Box>
        <Box display= "flex" alignItems="center">
          <Text color={textColor} size = "xl" fontWeight='700' m="4" width="30%">
            Stoploss
          </Text>   
          <NumberInputPer  width="100%" m="4"
            onChange={(valueString) => setOrderData({ ...orderData, stoploss: valueString })} 
          />
        </Box>
        {orderData.order_type === 'limit' && (
          <Box display= "flex" alignItems="center">
            <Text color={textColor} size = "xl" fontWeight='700' m="4" width="30%">
              Limit Price
            </Text>
            <Input placeholder='0' size='md' m="4" color={textColorSecondary}
              onChange={(e) => setOrderData({ ...orderData, price: parseFloat(e.target.value)})}
            />
          </Box>
        )}
        <Button
          isLoading={isLoading} 
          loadingText='Loading'
          colorScheme='teal'
          variant='outline'
          spinnerPlacement='start'
          width = "40%"
          alignSelf = "center"
          m="5"
          onClick={handleCreateOrder}
        >
          Create Order
        </Button>
    </Card>
  );
}