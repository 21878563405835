// Chakra imports
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { Input } from '@chakra-ui/react'
// Assets
import {  MdDragIndicator } from "react-icons/md";
import React, { useState, useEffect } from 'react';
// import { useEffect } from "react/cjs/react.production.min";

export default function SelectUser(props) {
  const { orderData, setOrderData, handleCreateOrder, usersList, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [users, setUsers] = useState(usersList);

  useEffect(() => {
    setUsers(usersList);
  }, [usersList]);

  const handleCheckboxChange = (index) => {
    const updatedUsers = [...users];
    if (index === -1) {
        updatedUsers.forEach((user) => {
          user.isChecked = true
        });
      setUsers(updatedUsers);
    } else if (index === -2) {
      updatedUsers.forEach((user) => {
        user.isChecked = false
      });
      setUsers(updatedUsers);
    } 
    else {
      updatedUsers[index].isChecked = !updatedUsers[index].isChecked;
      setUsers(updatedUsers);
    }

    const selectedUsers = updatedUsers.filter((user) => user.isChecked);
    const selectedUsersNames = selectedUsers.map((user) => user.name);
    setOrderData({ ...orderData, username: selectedUsersNames});
  };
  const allUsersChecked = users.every((user) => user.isChecked); // Check if all users are selected
  const [searchTerm, setSearchTerm] = useState('');

  const inputTextColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const inputPlaceholderColor = useColorModeValue('gray.400', 'whiteAlpha.600');

  return (
    <Card p="20px" align="center" direction="column" w="100%">
      <Flex alignItems="center" w="100%" mb="30px">
            <Checkbox
              me="16px"
              isChecked={allUsersChecked}
              onChange={() => handleCheckboxChange(allUsersChecked ? -2 : -1)}
              colorScheme="brandScheme"
              size ='lg'
            />
        <Text color={textColor} fontSize="lg" fontWeight="700">
          All Users
        </Text>
      </Flex>
      <Flex alignItems="center" w="100%" mb="30px">
        <Input placeholder="Search User" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          color={inputTextColor}
          _placeholder={{ color: inputPlaceholderColor }} 
        />
      </Flex>
      <Box px="11px">
        {users
        .filter((user) => 
          user.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((user, index) => (
          <Flex mb="20px" key={user.name}>
            <Checkbox
              me="16px"
              isChecked={user.isChecked}
              onChange={() => handleCheckboxChange(index)}
              colorScheme="brandScheme"
            />
            <Text
              fontWeight="bold"
              color={textColor}
              fontSize="md"
              textAlign="start"
            >
              {user.name}
            </Text>
            <Icon
              ms="auto"
              as={MdDragIndicator}
              color="secondaryGray.600"
              w="24px"
              h="24px"
            />
          </Flex>
        ))}
      </Box>
    </Card>
  );
}
