/* eslint-disable */

import { ArrowDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,

} from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import React, { useState, useEffect } from 'react';
// Assets
import { MdArrowUpward, MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
const columnHelper = createColumnHelper();
const lightModeColors = [
  '#FF5733', 
  '#33FF57',
  '#3357FF', 
  '#FF33A1',
  '#FFC300',
];

const darkModeColors = [
  '#C70039', 
  '#28A745',
  '#007BFF',
  '#FF69B4',
  '#FFC300',
];

const userColors = {};

const getUserColor = (userId, isDarkMode) => {
  if (userColors[userId]) {
    return userColors[userId];
  }
  
  const colors = isDarkMode ? darkModeColors : lightModeColors;
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  
  userColors[userId] = randomColor;
  
  return randomColor;
};
export default function PositionTable(props) {
  const { tableData, fetchPositions } = props;
  const [filterInput, setFilterInput] = useState({});
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;
  const [loadingRows, setLoadingRows] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  const alertStuffs = (type, message) =>{
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleClose = (rowData) => {
    setLoadingRows((prev) => ({ ...prev, [rowData.id]: true })); // Set loading for the specific row
    console.log(rowData)
    const closeData = {
      symbol: rowData.symbol,
      username: [rowData.username],
      market: rowData.market,
      order_type: 'market',
      leverage: rowData.leverage,
      entry: false,
      amount: rowData.amount,
      side: rowData.direction =="LONG" ? "sell" : "buy"
    };
    console.log(closeData);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/order/`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(closeData),
    })
    .then(response => response.json())
    .then(data => {
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false })); // Reset loading for the specific row
      fetchPositions();
      console.log('Order closing successful:', data);

      const successForUsers = [];
      const messageFailed = [];
      data.forEach((order) => {
        if (order['status'] === 'SUCCESS'){
          successForUsers.push(order['username']);
        } else {
          messageFailed.push(order['message']);
        }
      });
      if (successForUsers.length > 0){
        alertStuffs('success', `Order closed successfully for ${successForUsers.join(', ')}`);
      } else {
        alertStuffs('error', `Failed to close position because ${messageFailed[0]}`);
      }
    })
    .catch((error) => {
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false })); // Reset loading for the specific row
      alertStuffs('error', `Failed to create order`);
    });
  };

  const ClosePositionAlert = () => {
    return (
      <Alert
        status={alertType}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {alertType === 'success' ? 'Order Submitted!' : 'Order Failed!'}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {alertMessage}
        </AlertDescription>
      </Alert>
    );
  };
  

  const handleFilterChange = (columnId, value) => {
    setFilterInput((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };
  const inputTextColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  useEffect(() => {
    const filteredData = tableData.filter((row) =>
      columns.every((column) => {
        const columnId = column.id;
        const filterValue = filterInput[columnId];
        if (!filterValue) return true;
        return row[columnId]?.toString().toLowerCase().includes(filterValue.toLowerCase());
      })
    );
    setData(filteredData);
  }, [filterInput, tableData]);

  const columns = [
    columnHelper.accessor('close', {
      id: 'close',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CLOSE
        </Text>
      ),
      cell: (info) => {
        const isRowLoading = loadingRows[info.row.original.id];
        return (
          <Button
          isLoading={isRowLoading}
          loadingText='Loading'
          colorScheme='red'
          variant='outline'
          spinnerPlacement='start'
          onClick={() => handleClose(info.row.original)}
          isDisabled={["CLOSED", "NOT_APPLICABLE", "NOT_STARTED"].includes(info.row.original.status) ? true:false} // Disable the button when the row is loading
        >
          Close
        </Button>
        );
      },
    }),
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Username
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={getUserColor(info.getValue())} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('symbol', {
      id: 'symbol',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Symbol
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('current_price', {
      id: 'current_price',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Current Price
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('profit', {
      id: 'profit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Profit
        </Text>
      ),
      cell: (info) => (
        <Text
        color={info.getValue() > 0 ? 'green.400' : 'red.500'}
        fontSize="sm"
        fontWeight="700"
      >
        {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('stoploss', {
      id: 'stoploss',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Stop Loss
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('takeprofit', {
      id: 'takeprofit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Take Profit
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('market', {
      id: 'market',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Market
        </Text>
      ),
      cell: (info) => (
        <Text color={info.getValue() == 'SPOT' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('direction', {
      id: 'direction',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Direction
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
          w="24px"
          h="24px"
          me="5px"
          color={
            info.getValue() === 'LONG'
              ? 'green.500'
              : 'red.500'
          }
          as={
            info.getValue() === 'LONG'
              ? MdArrowUpward
              : ArrowDownIcon
          }
        />
        <Text color={info.getValue() == 'LONG' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      </Flex>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
            align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
          w="24px"
          h="24px"
          me="5px"
          color={
            info.getValue() === 'OPEN'
              ? 'green.500'
              : 'red.500'
          }
          as={
            info.getValue() === 'OPEN'
              ? MdCheckCircle
              : MdCancel
          }
        />
        <Text color={info.getValue() == 'OPEN' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      </Flex>
      ),
    }),
    columnHelper.accessor('source', {
      id: 'source',
      header: () => (
        <Text
          justifyContent="space-between"
            align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Source
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
          w="24px"
          h="24px"
          me="5px"
          color={
            info.getValue() === '0'
              ? 'green.500'
              : 'red.500'
          }
        />
        <Text color={info.getValue() == '0' ? 'teal.500' : 'orange.400'} fontSize="sm" fontWeight="700">
          {info.getValue() == 0 ? 'MANUAL': `TRADINGVIEW ${info.getValue()}`}
        </Text>
      </Flex>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX="scroll"
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Positions
        </Text>
      </Flex>
      {showAlert && <ClosePositionAlert />}
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                      <Input
                            my="2"
                            placeholder=""
                            value={filterInput[header.column.columnDef.id] || ''}
                            onChange={(e) => handleFilterChange(header.column.columnDef.id, e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            color={inputTextColor}
                        />
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
