/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

// Assets
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from "react"; // Import useState
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import SelectUser from "views/admin/default/components/SelectUser";
import CreateOrder from "views/admin/default/components/CreateOrder";
export default function UserReports() {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [users, setUsers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'
  const [totalData, setTotalData] = useState({
    total_users: 0,
    profits_today: 0,
    profits_week: 0,
    profits_month: 0,
    total_open_positions: 0,
    total_orders: 0
  })

  const alertStuffs = (type, message) =>{
      setAlertType(type);
      setAlertMessage(message);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
  };

  const handleCreateOrder = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/order/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setIsLoading(false);

        const successForUsers = [];
        const messageFailed = [];        
        data.forEach((order) => {
          if (order['status'] === 'SUCCESS'){
            successForUsers.push(order['username']);
          } else {
            messageFailed.push(order['message']);
          }
        });
        if (successForUsers.length > 0){
          alertStuffs('success', `Order submitted successfully for ${successForUsers.join(', ')}`);
        } else {
          setIsLoading(false);
          alertStuffs('error', `Failed to create order because ${messageFailed[0]}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alertStuffs('error', `Failed to create order`);
      });
  };

  const handleLoginUsers = () => {
    setIsLoadingLogin(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoadingLogin(false);
        console.log(data)
        const successForUsers = [];
        const failedForUsers = [];
        data.forEach((order) => {
          if (order['status'] === 'SUCCESS'){
            successForUsers.push(order['username']);
          } else {
            failedForUsers.push(order['username']);
          }
        });
        if (failedForUsers.length > 0){
          
          alertStuffs('error', `Cant Login for ${failedForUsers.join(', ')}`);
        } else {
          alertStuffs('success', `Logged in for All users`);
        }
      })
      .catch((error) => {
        alertStuffs('error', `Failed to Login`);
      });
  };


  const CreateOrderAlert = () => {
    return (
      <Alert
        status={alertType}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {alertType === 'success' ? 'Order Submitted!' : 'Order Failed!'}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {alertMessage}
        </AlertDescription>
      </Alert>
    );
  };
  


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/`);
        const data = await response.json();
        const usersList = data.map(user => ({ name: user.username, isChecked: false }));
        // console.log(usersList);
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard/`);
        const data = await response.json();
        setTotalData(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchData();
  }, []);

  const [orderData, setOrderData] = useState({
    username: "",
    symbol: "",
    multiplier: 1,
    order_type: 'market',  // Default to Limit
    side: 'buy',       // Default to Sell
    market: 'future', // Default to Future
    leverage: 1,
    takeprofit: '1.00',
    stoploss: '1.00',
    price: 1,
    entry: true
  });

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Users'
          value={totalData.total_users}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Profits Today'
          value={totalData.profits_today}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Profits This Week'
          value={totalData.profits_week}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Profits This month'
          value={totalData.profits_month}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='Total Open Positions'
          value={totalData.total_open_positions}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Orders'
          value={totalData.total_orders}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <SelectUser orderData={orderData} setOrderData={setOrderData} handleCreateOrder={handleCreateOrder} usersList = {users}/>
        <CreateOrder orderData={orderData} setOrderData={setOrderData} handleCreateOrder={handleCreateOrder} isLoading = {isLoading} handleLoginUsers = {handleLoginUsers} />
      </SimpleGrid>
      {showAlert && <CreateOrderAlert />}
    </Box>
  );
}
